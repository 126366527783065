const imageContext = require.context(
  '../avatars',
  false,
  /\.(jpg|jpeg|png|webp)$/,
);
const imageContext2 = require.context(
  '../customs',
  false,
  /\.(jpg|jpeg|png|webp)$/,
);
let avatar = imageContext.keys().sort(function (a, b) {
  var numA = parseInt(a.match(/155847_(\d+)/)[1], 10);
  var numB = parseInt(b.match(/155847_(\d+)/)[1], 10);
  return numA - numB;
});
// 이거 업데이트 할 때 마다 match 뒤에 숫자 바뀌니까 바꿔줘야함

let custom = imageContext2.keys().sort(function (a, b) {
  return a - b;
});

const avatarData = avatar.map(imageContext);
const customData = custom.map(imageContext2);

let avatarName = [
  'aaron',
  'aarune',
  'acerola-masters',
  'acerola-masters2',
  'acerola-masters3',
  'acerola',
  'acetrainer-gen1',
  'acetrainer-gen1rb',
  'acetrainer-gen2',
  'acetrainer-gen3',
  'acetrainer-gen3jp',
  'acetrainer-gen3rs',
  'acetrainer-gen4',
  'acetrainer-gen4dp',
  'acetrainer-gen6',
  'acetrainer-gen6xy',
  'acetrainer-gen7',
  'acetrainer',
  'acetrainercouple-gen3',
  'acetrainercouple',
  'acetrainerf-gen1',
  'acetrainerf-gen1rb',
  'acetrainerf-gen2',
  'acetrainerf-gen3',
  'acetrainerf-gen3rs',
  'acetrainerf-gen4',
  'acetrainerf-gen4dp',
  'acetrainerf-gen6',
  'acetrainerf-gen6xy',
  'acetrainerf-gen7',
  'acetrainerf',
  'acetrainersnow',
  'acetrainersnowf',
  'adaman-masters',
  'adaman',
  'aetheremployee',
  'aetheremployeef',
  'aetherfoundation',
  'aetherfoundation2',
  'aetherfoundationf',
  'agatha-gen1',
  'agatha-gen1rb',
  'agatha-gen3',
  'agatha-lgpe',
  'akari-isekai',
  'akari',
  'alain',
  'alder',
  'alec-anime',
  'allister-masters',
  'allister-unmasked',
  'allister',
  'amarys',
  'amelia-shuffle',
  'anabel-gen3',
  'anabel-gen7',
  'anabel',
  'anthe',
  'anthea',
  'anvin',
  'aquagrunt-rse',
  'aquagrunt',
  'aquagruntf-rse',
  'aquagruntf',
  'aquasuit',
  'archer',
  'archie-gen3',
  'archie-gen6',
  'archie-usum',
  'arezu',
  'argenta',
  'ariana',
  'arlo',
  'aromalady-gen3',
  'aromalady-gen3rs',
  'aromalady-gen6',
  'aromalady',
  'artist-gen4',
  'artist-gen6',
  'artist-gen8',
  'artist-gen9',
  'artist',
  'artistf-gen6',
  'arven-s',
  'arven-v',
  'ash-alola',
  'ash-capbackward',
  'ash-hoenn',
  'ash-johto',
  'ash-kalos',
  'ash-sinnoh',
  'ash-unova',
  'ash',
  'atticus',
  'avery',
  'az',
  'backers',
  'backersf',
  'backpacker-gen6',
  'backpacker-gen8',
  'backpacker-gen9',
  'backpacker',
  'backpackerf',
  'baker',
  'ballguy',
  'barry-masters',
  'barry',
  'battlegirl-gen3',
  'battlegirl-gen4',
  'battlegirl-gen6',
  'battlegirl-gen6xy',
  'battlegirl',
  'bea-masters',
  'bea',
  'beauty-gen1',
  'beauty-gen1rb',
  'beauty-gen2',
  'beauty-gen2jp',
  'beauty-gen3',
  'beauty-gen3rs',
  'beauty-gen4dp',
  'beauty-gen5bw2',
  'beauty-gen6',
  'beauty-gen6xy',
  'beauty-gen7',
  'beauty-gen8',
  'beauty-gen9',
  'beauty-masters',
  'beauty',
  'bede-leader',
  'bede-masters',
  'bede',
  'bellelba',
  'bellepa',
  'bellhop',
  'bellis',
  'benga',
  'beni-ninja',
  'beni',
  'bertha',
  'bianca-masters',
  'bianca-pwt',
  'bianca',
  'biker-gen1',
  'biker-gen1rb',
  'biker-gen2',
  'biker-gen3',
  'biker-gen4',
  'biker',
  'bill-gen3',
  'birch-gen3',
  'birch',
  'birdkeeper-gen1',
  'birdkeeper-gen1rb',
  'birdkeeper-gen2',
  'birdkeeper-gen3',
  'birdkeeper-gen3rs',
  'birdkeeper-gen4dp',
  'birdkeeper-gen6',
  'birdkeeper',
  'blackbelt-gen1',
  'blackbelt-gen1rb',
  'blackbelt-gen2',
  'blackbelt-gen3',
  'blackbelt-gen3rs',
  'blackbelt-gen4',
  'blackbelt-gen4dp',
  'blackbelt-gen6',
  'blackbelt-gen7',
  'blackbelt-gen8',
  'blackbelt-gen9',
  'blackbelt',
  'blaine-gen1',
  'blaine-gen1rb',
  'blaine-gen2',
  'blaine-gen3',
  'blaine-lgpe',
  'blaine',
  'blanche-casual',
  'blanche',
  'blue-gen1',
  'blue-gen1champion',
  'blue-gen1rb',
  'blue-gen1rbchampion',
  'blue-gen1rbtwo',
  'blue-gen1two',
  'blue-gen2',
  'blue-gen3',
  'blue-gen3champion',
  'blue-gen3two',
  'blue-gen7',
  'blue-lgpe',
  'blue-masters',
  'blue-masters2',
  'blue',
  'boarder-gen2',
  'boarder',
  'bodybuilder-gen9',
  'bodybuilderf-gen9',
  'brandon-gen3',
  'brandon',
  'brassius',
  'brawly-gen3',
  'brawly-gen6',
  'brawly',
  'brendan-contest',
  'brendan-e',
  'brendan-gen3',
  'brendan-gen3rs',
  'brendan-masters',
  'brendan-rs',
  'brendan',
  'briar',
  'brigette',
  'brock-gen1',
  'brock-gen1rb',
  'brock-gen2',
  'brock-gen3',
  'brock-lgpe',
  'brock-masters',
  'brock',
  'bruno-gen1',
  'bruno-gen1rb',
  'bruno-gen2',
  'bruno-gen3',
  'bruno',
  'brycen',
  'brycenman',
  'bryony',
  'buck',
  'bugcatcher-gen1',
  'bugcatcher-gen1rb',
  'bugcatcher-gen2',
  'bugcatcher-gen3',
  'bugcatcher-gen3rs',
  'bugcatcher-gen4dp',
  'bugcatcher-gen6',
  'bugcatcher',
  'bugmaniac-gen3',
  'bugmaniac-gen6',
  'bugsy-gen2',
  'bugsy',
  'burgh-masters',
  'burgh',
  'burglar-gen1',
  'burglar-gen1rb',
  'burglar-gen2',
  'burglar-gen3',
  'burglar-lgpe',
  'burglar',
  'burnet-radar',
  'burnet',
  'butler',
  'byron',
  'cabbie-gen9',
  'cabbie',
  'cafemaster',
  'caitlin-gen4',
  'caitlin-masters',
  'caitlin',
  'calaba',
  'calem-masters',
  'calem',
  'cameraman-gen6',
  'cameraman-gen8',
  'cameraman',
  'camper-gen2',
  'camper-gen3',
  'camper-gen3rs',
  'camper-gen6',
  'camper',
  'candela-casual',
  'candela',
  'candice-masters',
  'candice',
  'caraliss',
  'caretaker',
  'carmine-festival',
  'carmine',
  'cedricjuniper',
  'celio',
  'channeler-gen1',
  'channeler-gen1rb',
  'channeler-gen3',
  'channeler-lgpe',
  'charm',
  'charon',
  'chase',
  'chef',
  'cheren-gen5bw2',
  'cheren-masters',
  'cheren',
  'cheryl',
  'chili',
  'choy',
  'christoph',
  'chuck-gen2',
  'chuck',
  'cilan',
  'clair-gen2',
  'clair-masters',
  'clair',
  'clavell-s',
  'clay',
  'clemont',
  'clerk-boss',
  'clerk-gen8',
  'clerk-unite',
  'clerk',
  'clerkf-gen8',
  'clerkf',
  'cliff',
  'clive-v',
  'clover',
  'clown',
  'cogita',
  'coin',
  'collector-gen3',
  'collector-gen6',
  'collector-gen7',
  'collector-masters',
  'collector',
  'colress-gen7',
  'colress',
  'colza',
  'concordia',
  'cook-gen7',
  'cook-gen9',
  'cook',
  'courier',
  'courtney-gen3',
  'courtney',
  'cowgirl',
  'crasherwake',
  'cress',
  'crispin',
  'crushgirl-gen3',
  'crushkin-gen3',
  'cueball-gen1',
  'cueball-gen1rb',
  'cueball-gen3',
  'curtis',
  'cyclist-gen4',
  'cyclist',
  'cyclistf-gen4',
  'cyclistf',
  'cyllene',
  'cynthia-anime',
  'cynthia-anime2',
  'cynthia-gen4',
  'cynthia-gen7',
  'cynthia-masters',
  'cynthia-masters2',
  'cynthia-masters3',
  'cynthia',
  'cyrano',
  'cyrus-masters',
  'cyrus',
  'dagero',
  'dahlia',
  'daisy-gen3',
  'dana',
  'dancer-gen7',
  'dancer-gen8',
  'dancer',
  'darach-caitlin',
  'darach',
  'dawn-contest',
  'dawn-gen4pt',
  'dawn-masters',
  'dawn-masters2',
  'dawn-masters3',
  'dawn',
  'delinquent-gen9',
  'delinquent',
  'delinquentf-gen9',
  'delinquentf2-gen9',
  'dendra',
  'depotagent',
  'dexio-gen6',
  'dexio',
  'diamondclanmember',
  'diantha-masters',
  'diantha-masters2',
  'diantha',
  'doctor-gen8',
  'doctor',
  'doctorf-gen8',
  'doubleteam',
  'dragontamer-gen3',
  'dragontamer-gen6',
  'dragontamer-gen9',
  'dragontamer',
  'drake-gen3',
  'drasna',
  'drayden',
  'drayton',
  'dulse',
  'elaine',
  'elesa-gen5bw2',
  'elesa-masters',
  'elesa-masters2',
  'elesa',
  'elio-usum',
  'elio',
  'elm',
  'emma',
  'emmet-masters',
  'emmet',
  'engineer-gen1',
  'engineer-gen1rb',
  'engineer-gen3',
  'erbie-unite',
  'eri',
  'erika-gen1',
  'erika-gen1rb',
  'erika-gen2',
  'erika-gen3',
  'erika-lgpe',
  'erika-masters',
  'erika-masters2',
  'erika',
  'essentia',
  'ethan-gen2',
  'ethan-gen2c',
  'ethan-masters',
  'ethan-pokeathlon',
  'ethan',
  'eusine-gen2',
  'eusine',
  'evelyn',
  'expert-gen3',
  'expert-gen6',
  'expertf-gen3',
  'expertf-gen6',
  'faba',
  'fairytalegirl',
  'falkner-gen2',
  'falkner',
  'fantina',
  'fennel',
  'firebreather-gen2',
  'firebreather',
  'firefighter',
  'fisher-gen8',
  'fisherman-gen1',
  'fisherman-gen1rb',
  'fisherman-gen2jp',
  'fisherman-gen3',
  'fisherman-gen3rs',
  'fisherman-gen4',
  'fisherman-gen6',
  'fisherman-gen6xy',
  'fisherman-gen7',
  'fisherman',
  'flannery-gen3',
  'flannery-gen6',
  'flannery',
  'flaregrunt',
  'flaregruntf',
  'flint',
  'florian-bb',
  'florian-festival',
  'florian-s',
  'freediver',
  'furisodegirl-black',
  'furisodegirl-blue',
  'furisodegirl-pink',
  'furisodegirl-white',
  'gaeric',
  'galacticgrunt',
  'galacticgruntf',
  'gambler-gen1',
  'gambler-gen1rb',
  'gambler',
  'gamer-gen3',
  'garcon',
  'gardener',
  'gardenia-masters',
  'gardenia',
  'geeta',
  'gentleman-gen1',
  'gentleman-gen1rb',
  'gentleman-gen2',
  'gentleman-gen3',
  'gentleman-gen3rs',
  'gentleman-gen4',
  'gentleman-gen4dp',
  'gentleman-gen6',
  'gentleman-gen6xy',
  'gentleman-gen7',
  'gentleman-gen8',
  'gentleman-lgpe',
  'gentleman',
  'ghetsis-gen5bw',
  'ghetsis',
  'giacomo',
  'ginchiyo-conquest',
  'ginter',
  'giovanni-gen1',
  'giovanni-gen1rb',
  'giovanni-gen3',
  'giovanni-lgpe',
  'giovanni-masters',
  'giovanni',
  'glacia-gen3',
  'glacia',
  'gladion-masters',
  'gladion-stance',
  'gladion',
  'gloria-dojo',
  'gloria-league',
  'gloria-masters',
  'gloria-tundra',
  'gloria',
  'golfer',
  'gordie',
  'grace',
  'grant',
  'green',
  'greta-gen3',
  'greta',
  'grimsley-gen7',
  'grimsley-masters',
  'grimsley',
  'grusha',
  'guitarist-gen2',
  'guitarist-gen3',
  'guitarist-gen4',
  'guitarist-gen6',
  'guitarist',
  'gurkinn',
  'guzma-masters',
  'guzma',
  'hala',
  'hanbei-conquest',
  'hapu',
  'harlequin',
  'hassel',
  'hau-masters',
  'hau-stance',
  'hau',
  'hayley',
  'heath',
  'hero-conquest',
  'hero2-conquest',
  'heroine-conquest',
  'heroine2-conquest',
  'hexmaniac-gen3',
  'hexmaniac-gen3jp',
  'hexmaniac-gen6',
  'hiker-gen1',
  'hiker-gen1rb',
  'hiker-gen2',
  'hiker-gen3',
  'hiker-gen3rs',
  'hiker-gen4',
  'hiker-gen6',
  'hiker-gen7',
  'hiker-gen8',
  'hiker-gen9',
  'hiker',
  'hilbert-masters',
  'hilbert-masters2',
  'hilbert-wonderlauncher',
  'hilbert',
  'hilda-masters',
  'hilda-masters2',
  'hilda-masters3',
  'hilda-wonderlauncher',
  'hilda',
  'hooligans',
  'hoopster',
  'hop-masters',
  'hop',
  'hugh',
  'hyde',
  'idol',
  'ilima',
  'infielder',
  'ingo-hisui',
  'ingo-masters',
  'ingo',
  'interviewers-gen3',
  'interviewers-gen6',
  'interviewers',
  'iono',
  'irida-masters',
  'irida',
  'iris-gen5bw2',
  'iris-masters',
  'iris',
  'iscan',
  'jacq',
  'jamie',
  'janine-gen2',
  'janine',
  'janitor-gen7',
  'janitor-gen9',
  'janitor',
  'jasmine-contest',
  'jasmine-gen2',
  'jasmine-masters',
  'jasmine-masters2',
  'jasmine',
  'jessiejames-gen1',
  'jogger',
  'johanna-contest',
  'johanna',
  'jrtrainer-gen1',
  'jrtrainer-gen1rb',
  'jrtrainerf-gen1',
  'jrtrainerf-gen1rb',
  'juan-gen3',
  'juan',
  'juggler-gen1',
  'juggler-gen1rb',
  'juggler-gen2',
  'juggler-gen3',
  'juggler',
  'juliana-bb',
  'juliana-festival',
  'juliana-s',
  'juniper',
  'jupiter',
  'kabu',
  'kahili',
  'kamado-armor',
  'kamado',
  'karen-gen2',
  'karen',
  'katy',
  'kiawe',
  'kieran-champion',
  'kieran-festival',
  'kieran',
  'kimonogirl-gen2',
  'kimonogirl',
  'kindler-gen3',
  'kindler-gen6',
  'klara',
  'kofu',
  'koga-gen1',
  'koga-gen1rb',
  'koga-gen2',
  'koga-gen3',
  'koga-lgpe',
  'koga',
  'korrina-masters',
  'korrina',
  'kris-gen2',
  'kris-masters',
  'kris',
  'kukui-stand',
  'kukui',
  'kunoichi-conquest',
  'kunoichi2-conquest',
  'kurt',
  'lacey',
  'lady-gen3',
  'lady-gen3rs',
  'lady-gen4',
  'lady-gen6',
  'lady-gen6oras',
  'lady',
  'lana-masters',
  'lana',
  'lance-gen1',
  'lance-gen1rb',
  'lance-gen2',
  'lance-gen3',
  'lance-lgpe',
  'lance-masters',
  'lance',
  'lanette',
  'larry',
  'lass-gen1',
  'lass-gen1rb',
  'lass-gen2',
  'lass-gen3',
  'lass-gen3rs',
  'lass-gen4',
  'lass-gen4dp',
  'lass-gen6',
  'lass-gen6oras',
  'lass-gen7',
  'lass-gen8',
  'lass',
  'laventon',
  'laventon2',
  'leaf-gen3',
  'leaf-masters',
  'leaf-masters2',
  'leaguestaff',
  'leaguestafff',
  'lenora',
  'leon-masters',
  'leon-masters2',
  'leon-tower',
  'leon',
  'li',
  'lian',
  'lillie-masters',
  'lillie-masters2',
  'lillie-masters3',
  'lillie-z',
  'lillie',
  'linebacker',
  'lisia-masters',
  'lisia',
  'liza-gen6',
  'liza-masters',
  'liza',
  'lorelei-gen1',
  'lorelei-gen1rb',
  'lorelei-gen3',
  'lorelei-lgpe',
  'ltsurge-gen1',
  'ltsurge-gen1rb',
  'ltsurge-gen2',
  'ltsurge-gen3',
  'ltsurge',
  'lucas-contest',
  'lucas-gen4pt',
  'lucas',
  'lucian',
  'lucy-gen3',
  'lucy',
  'lusamine-masters',
  'lusamine-nihilego',
  'lusamine',
  'lyra-masters',
  'lyra-masters2',
  'lyra-pokeathlon',
  'lyra',
  'lysandre-masters',
  'lysandre',
  'madame-gen4',
  'madame-gen4dp',
  'madame-gen6',
  'madame-gen7',
  'madame-gen8',
  'madame',
  'magmagrunt-rse',
  'magmagrunt',
  'magmagruntf-rse',
  'magmagruntf',
  'magmasuit',
  'magnolia',
  'magnus',
  'mai',
  'maid-gen4',
  'maid-gen6',
  'maid',
  'mallow-masters',
  'mallow',
  'malva',
  'marley',
  'marlon',
  'marnie-league',
  'marnie-masters',
  'marnie-masters2',
  'marnie-masters3',
  'marnie',
  'mars',
  'marshal',
  'masamune-conquest',
  'mateo',
  'matt-gen3',
  'matt',
  'maxie-gen3',
  'maxie-gen6',
  'may-contest',
  'may-e',
  'may-gen3',
  'may-gen3rs',
  'may-masters',
  'may-masters2',
  'may-masters3',
  'may-rs',
  'may',
  'maylene',
  'medium-gen2jp',
  'medium',
  'mela',
  'melli',
  'melony',
  'miku-fire',
  'miku-flying',
  'miku-grass',
  'miku-ground',
  'miku-psychic',
  'miku-water',
  'milo',
  'mina-lgpe',
  'mina-masters',
  'mina',
  'mira',
  'miriam',
  'mirror',
  'misty-gen1',
  'misty-gen1rb',
  'misty-gen2',
  'misty-gen3',
  'misty-lgpe',
  'misty-masters',
  'misty',
  'model-gen8',
  'mohn-anime',
  'mohn',
  'molayne',
  'mom-alola',
  'mom-hoenn',
  'mom-johto',
  'mom-paldea',
  'mom-unova',
  'mom-unova2',
  'morgan',
  'morty-gen2',
  'morty-masters',
  'morty-masters2',
  'morty',
  'mrbriney',
  'mrfuji-gen3',
  'mrstone',
  'musician-gen8',
  'musician-gen9',
  'musician',
  'mustard-champion',
  'mustard-master',
  'mustard',
  'n-masters',
  'n-masters2',
  'n-masters3',
  'n',
  'nancy',
  'nanu',
  'nate-masters',
  'nate-pokestar',
  'nate-pokestar3',
  'nate-wonderlauncher',
  'nate',
  'nemona-s',
  'nemona-v',
  'neroli',
  'nessa-masters',
  'nessa',
  'ninjaboy-gen3',
  'ninjaboy-gen6',
  'ninjaboy',
  'nita',
  'nobunaga-conquest',
  'noland-gen3',
  'noland',
  'norman-gen3',
  'norman-gen6',
  'norman',
  'nurse',
  'nurseryaide',
  'oak-gen1',
  'oak-gen1rb',
  'oak-gen2',
  'oak-gen3',
  'oak',
  'officer-gen2',
  'officeworker-gen9',
  'officeworker',
  'officeworkerf-gen9',
  'officeworkerf',
  'ogreclan',
  'oichi-conquest',
  'oldcouple-gen3',
  'oleana',
  'olivia',
  'olympia',
  'opal',
  'ortega',
  'owner',
  'painter-gen3',
  'palina',
  'palmer',
  'parasollady-gen3',
  'parasollady-gen4',
  'parasollady-gen6',
  'parasollady',
  'paulo-masters',
  'pearlclanmember',
  'penny',
  'peonia',
  'peony-league',
  'peony',
  'perrin',
  'pesselle',
  'petrel',
  'phil',
  'phoebe-gen3',
  'phoebe-gen6',
  'phoebe-masters',
  'phorus-unite',
  'phyco',
  'picnicker-gen2',
  'picnicker-gen3',
  'picnicker-gen3rs',
  'picnicker-gen6',
  'picnicker',
  'piers-league',
  'piers-masters',
  'piers',
  'pilot',
  'plasmagrunt-gen5bw',
  'plasmagrunt',
  'plasmagruntf-gen5bw',
  'plasmagruntf',
  'player-go',
  'playerf-go',
  'plumeria-league',
  'plumeria',
  'pokefan-gen2',
  'pokefan-gen3',
  'pokefan-gen4',
  'pokefan-gen6',
  'pokefan-gen6xy',
  'pokefan',
  'pokefanf-gen2',
  'pokefanf-gen3',
  'pokefanf-gen4',
  'pokefanf-gen6',
  'pokefanf-gen6xy',
  'pokefanf',
  'pokekid-gen8',
  'pokekid',
  'pokekidf-gen8',
  'pokemaniac-gen1',
  'pokemaniac-gen1rb',
  'pokemaniac-gen2',
  'pokemaniac-gen3',
  'pokemaniac-gen3rs',
  'pokemaniac-gen6',
  'pokemaniac-gen9',
  'pokemaniac',
  'pokemonbreeder-gen3',
  'pokemonbreeder-gen4',
  'pokemonbreeder-gen6',
  'pokemonbreeder-gen6xy',
  'pokemonbreeder-gen7',
  'pokemonbreeder-gen8',
  'pokemonbreeder',
  'pokemonbreederf-gen3',
  'pokemonbreederf-gen3frlg',
  'pokemonbreederf-gen4',
  'pokemonbreederf-gen6',
  'pokemonbreederf-gen6xy',
  'pokemonbreederf-gen7',
  'pokemonbreederf-gen8',
  'pokemonbreederf',
  'pokemoncenterlady',
  'pokemonranger-gen3',
  'pokemonranger-gen3rs',
  'pokemonranger-gen4',
  'pokemonranger-gen6',
  'pokemonranger-gen6xy',
  'pokemonranger',
  'pokemonrangerf-gen3',
  'pokemonrangerf-gen3rs',
  'pokemonrangerf-gen4',
  'pokemonrangerf-gen6',
  'pokemonrangerf-gen6xy',
  'pokemonrangerf',
  'policeman-gen4',
  'policeman-gen7',
  'policeman-gen8',
  'policeman',
  'poppy',
  'postman',
  'preschooler-gen6',
  'preschooler-gen7',
  'preschooler',
  'preschoolerf-gen6',
  'preschoolerf-gen7',
  'preschoolerf',
  'preschoolers',
  'proton',
  'pryce-gen2',
  'pryce',
  'psychic-gen1',
  'psychic-gen1rb',
  'psychic-gen2',
  'psychic-gen3',
  'psychic-gen3rs',
  'psychic-gen4',
  'psychic-gen6',
  'psychic-lgpe',
  'psychic',
  'psychicf-gen3',
  'psychicf-gen3rs',
  'psychicf-gen4',
  'psychicf',
  'psychicfjp-gen3',
  'punkgirl-gen7',
  'punkgirl-masters',
  'punkgirl',
  'punkguy-gen7',
  'punkguy',
  'raifort',
  'raihan-masters',
  'raihan',
  'railstaff',
  'ramos',
  'rancher',
  'ranmaru-conquest',
  'red-gen1',
  'red-gen1main',
  'red-gen1rb',
  'red-gen1title',
  'red-gen2',
  'red-gen3',
  'red-gen7',
  'red-lgpe',
  'red-masters',
  'red-masters2',
  'red-masters3',
  'red',
  'rei-isekai',
  'rei',
  'reporter-gen6',
  'reporter-gen8',
  'reporter',
  'rhi',
  'richboy-gen3',
  'richboy-gen4',
  'richboy-gen6',
  'richboy-gen6xy',
  'richboy',
  'rika',
  'riley',
  'risingstar-gen6',
  'risingstar',
  'risingstarf-gen6',
  'risingstarf',
  'rita',
  'river',
  'roark',
  'rocker-gen1',
  'rocker-gen1rb',
  'rocker-gen3',
  'rocket-gen1',
  'rocket-gen1rb',
  'rocketexecutive-gen2',
  'rocketexecutivef-gen2',
  'rocketgrunt-gen2',
  'rocketgrunt',
  'rocketgruntf-gen2',
  'rocketgruntf',
  'rollerskater',
  'rollerskaterf',
  'rood',
  'rosa-masters',
  'rosa-masters2',
  'rosa-masters3',
  'rosa-pokestar',
  'rosa-pokestar2',
  'rosa-pokestar3',
  'rosa-wonderlauncher',
  'rosa',
  'rose-zerosuit',
  'rose',
  'roughneck-gen4',
  'roughneck',
  'rowan',
  'roxanne-gen3',
  'roxanne-gen6',
  'roxanne-masters',
  'roxanne',
  'roxie-masters',
  'roxie',
  'ruffian',
  'ruinmaniac-gen3',
  'ruinmaniac-gen3rs',
  'ruinmaniac-gen6',
  'ruinmaniac',
  'rye',
  'ryme',
  'ryuki',
  'sabi',
  'sabrina-frlg',
  'sabrina-gen1',
  'sabrina-gen1rb',
  'sabrina-gen2',
  'sabrina-gen3',
  'sabrina-lgpe',
  'sabrina-masters',
  'sabrina',
  'sada-ai',
  'sada',
  'sage-gen2',
  'sage-gen2jp',
  'sage',
  'saguaro',
  'sailor-gen1',
  'sailor-gen1rb',
  'sailor-gen2',
  'sailor-gen3',
  'sailor-gen3jp',
  'sailor-gen3rs',
  'sailor-gen6',
  'sailor',
  'salvatore',
  'samsonoak',
  'sanqua',
  'saturn',
  'schoolboy-gen2',
  'schoolboy',
  'schoolgirl',
  'schoolkid-gen3',
  'schoolkid-gen4',
  'schoolkid-gen4dp',
  'schoolkid-gen6',
  'schoolkid-gen8',
  'schoolkid',
  'schoolkidf-gen3',
  'schoolkidf-gen4',
  'schoolkidf-gen6',
  'schoolkidf-gen8',
  'schoolkidf',
  'scientist-gen1',
  'scientist-gen1rb',
  'scientist-gen2',
  'scientist-gen3',
  'scientist-gen4',
  'scientist-gen4dp',
  'scientist-gen6',
  'scientist-gen7',
  'scientist-gen9',
  'scientist',
  'scientistf-gen6',
  'scientistf',
  'scott',
  'scottie-masters',
  'scubadiver',
  'securitycorps',
  'securitycorpsf',
  'selene-masters',
  'selene-usum',
  'selene',
  'serena-anime',
  'serena-masters',
  'serena-masters2',
  'serena-masters3',
  'serena',
  'shadowtriad',
  'shauna-masters',
  'shauna',
  'shauntal',
  'shelly-gen3',
  'shelly',
  'shielbert',
  'sidney-gen3',
  'sidney',
  'siebold-masters',
  'siebold',
  'sierra',
  'sightseer',
  'sightseerf',
  'silver-gen2',
  'silver-gen2kanto',
  'silver-masters',
  'silver',
  'sina-gen6',
  'sina',
  'sisandbro-gen3',
  'sisandbro-gen3rs',
  'sisandbro',
  'skier-gen2',
  'skier',
  'skierf-gen4dp',
  'skierf',
  'skullgrunt',
  'skullgruntf',
  'skyla-masters',
  'skyla-masters2',
  'skyla',
  'skytrainer',
  'skytrainerf',
  'smasher',
  'soliera',
  'sonia-masters',
  'sonia-professor',
  'sonia',
  'sophocles',
  'sordward-shielbert',
  'sordward',
  'spark-casual',
  'spark',
  'spenser-gen3',
  'spenser',
  'srandjr-gen3',
  'stargrunt-s',
  'stargrunt-v',
  'stargruntf-s',
  'stargruntf-v',
  'steven-gen3',
  'steven-gen6',
  'steven-masters',
  'steven-masters2',
  'steven-masters3',
  'steven-masters4',
  'steven',
  'streetthug-masters',
  'streetthug',
  'striker',
  'supernerd-gen1',
  'supernerd-gen1rb',
  'supernerd-gen2',
  'supernerd-gen3',
  'supernerd',
  'surfer',
  'swimmer-gen1',
  'swimmer-gen1rb',
  'swimmer-gen4',
  'swimmer-gen4dp',
  'swimmer-gen4jp',
  'swimmer-gen6',
  'swimmer-gen7',
  'swimmer-gen8',
  'swimmer-masters',
  'swimmer',
  'swimmerf-gen2',
  'swimmerf-gen3',
  'swimmerf-gen3rs',
  'swimmerf-gen4',
  'swimmerf-gen4dp',
  'swimmerf-gen6',
  'swimmerf-gen7',
  'swimmerf-gen8',
  'swimmerf',
  'swimmerf2-gen6',
  'swimmerf2-gen7',
  'swimmerfjp-gen2',
  'swimmerm-gen2',
  'swimmerm-gen3',
  'swimmerm-gen3rs',
  'sycamore-masters',
  'sycamore',
  'tabitha-gen3',
  'tabitha',
  'tamer-gen1',
  'tamer-gen1rb',
  'tamer-gen3',
  'taohua',
  'tate-gen6',
  'tate-masters',
  'tate',
  'tateandliza-gen3',
  'tateandliza-gen6',
  'teacher-gen2',
  'teacher-gen7',
  'teacher',
  'teamaquabeta-gen3',
  'teamaquagruntf-gen3',
  'teamaquagruntm-gen3',
  'teammagmagruntf-gen3',
  'teammagmagruntm-gen3',
  'teammates',
  'teamrocket',
  'teamrocketgruntf-gen3',
  'teamrocketgruntm-gen3',
  'theroyal',
  'thorton',
  'tierno',
  'tina-masters',
  'toddsnap',
  'toddsnap2',
  'tourist',
  'touristf',
  'touristf2',
  'trevor',
  'trialguide',
  'trialguidef',
  'triathletebiker-gen6',
  'triathletebikerf-gen3',
  'triathletebikerm-gen3',
  'triathleterunner-gen6',
  'triathleterunnerf-gen3',
  'triathleterunnerm-gen3',
  'triathleteswimmer-gen6',
  'triathleteswimmerf-gen3',
  'triathleteswimmerm-gen3',
  'tuber-gen3',
  'tuber-gen6',
  'tuber',
  'tuberf-gen3',
  'tuberf-gen3rs',
  'tuberf-gen6',
  'tuberf',
  'tucker-gen3',
  'tucker',
  'tuli',
  'tulip',
  'turo-ai',
  'turo',
  'twins-gen2',
  'twins-gen3',
  'twins-gen3rs',
  'twins-gen4',
  'twins-gen4dp',
  'twins-gen6',
  'twins',
  'tyme',
  'ultraforestkartenvoy',
  'unknown',
  'unknownf',
  'valerie',
  'vessa',
  'veteran-gen4',
  'veteran-gen6',
  'veteran-gen7',
  'veteran',
  'veteranf-gen6',
  'veteranf-gen7',
  'veteranf',
  'victor-dojo',
  'victor-league',
  'victor-masters',
  'victor-tundra',
  'victor',
  'vince',
  'viola-masters',
  'viola',
  'volkner-masters',
  'volkner',
  'volo-ginkgo',
  'volo',
  'waiter-gen4',
  'waiter-gen4dp',
  'waiter-gen9',
  'waiter',
  'waitress-gen4',
  'waitress-gen6',
  'waitress-gen9',
  'waitress',
  'wallace-gen3',
  'wallace-gen3rs',
  'wallace-gen6',
  'wallace-masters',
  'wallace',
  'wally-gen3',
  'wally-masters',
  'wally-rse',
  'wally',
  'wattson-gen3',
  'wattson',
  'whitney-gen2',
  'whitney-masters',
  'whitney',
  'wicke',
  'wikstrom',
  'will-gen2',
  'will',
  'willow-casual',
  'willow',
  'winona-gen3',
  'winona-gen6',
  'winona',
  'worker-gen4',
  'worker-gen6',
  'worker-gen7',
  'worker-gen8',
  'worker-gen9',
  'worker-lgpe',
  'worker',
  'worker2-gen6',
  'workerf-gen8',
  'workerice',
  'wulfric',
  'xerosic',
  'yancy',
  'yellgrunt',
  'yellgruntf',
  'yellow',
  'youngathlete',
  'youngathletef',
  'youngcouple-gen3',
  'youngcouple-gen3rs',
  'youngcouple-gen4dp',
  'youngcouple-gen6',
  'youngcouple',
  'youngn',
  'youngster-gen1',
  'youngster-gen1rb',
  'youngster-gen2',
  'youngster-gen3',
  'youngster-gen3rs',
  'youngster-gen4',
  'youngster-gen4dp',
  'youngster-gen6',
  'youngster-gen6xy',
  'youngster-gen7',
  'youngster-gen8',
  'youngster-gen9',
  'youngster-masters',
  'youngster',
  'yukito-hideko',
  'zinnia-masters',
  'zinnia',
  'zinzolin',
  'zirco-unite',
  'zisu',
  'zossie',
];

let atdb = [];
for (let i = 0; i < avatarName.length; i++) {
  atdb.push({
    name: avatarName[i],
    url: avatarData[i],
  });
}
for (let i = 0; i < custom.length; i++) {
  atdb.push({
    name: custom[i].split('-')[1].split('.')[0],
    url: customData[i],
  });
}
atdb = atdb.sort((x, y) => x.name.localeCompare(y.name));
export { atdb };
